<!-- TEMPLATE -->
<template>
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs" divider=">" large></v-breadcrumbs>
      <v-card v-if="timesheetStatusText && timesheetUserName" class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content
            <v-list-item-title
              class="headline text-left white--text"
            >{{timesheetUserName}} - {{timesheetStatusText}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <TimeSheetDetails :timeSheetId="timesheetId"></TimeSheetDetails>
      <TimeSheetSummary></TimeSheetSummary>
      <!-- REJECT BTN -->
      <v-row v-if="hasPermission && timeSheetStatus.StatusID" justify="end">
        <v-col sm="auto">
          <v-btn @click="dialog = !dialog" outlined color="primary">
            <v-icon left>mdi-calendar-remove-outline</v-icon>Reject
          </v-btn>
        </v-col>
        <!-- VERIFY BTN -->
        <v-col sm="auto" v-if="timeSheetStatus.StatusID && timeSheetStatus.StatusID != 4">
          <v-btn color="primary" @click="submitNextStatus">
            <v-icon left>mdi-calendar-check-outline</v-icon>
            {{submitText}}
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog modal v-model="dialog" width="450">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text">Reject Reason?</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col sm="12">
                <v-text-field label="Reason for rejecting timesheet?" v-model="rejectReason"></v-text-field>
              </v-col>
              <v-col sm="12">
                <v-card-actions>
                  <v-btn @click="rejectTimeSheet" color="primary">Reject</v-btn>
                  <v-btn outlined color="primary" @click="dialog = !dialog">Cancel</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
</template>
<!-- SCRIPTS -->
<script>
import {debounce, isEqual, filter, sortBy, range} from 'lodash'
import dayjs from 'dayjs'
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import TimeSheetDetails from '@components/time/timesheetDetails'
import TimeSheetEntry from '@classes/TimeSheetEntry'
import TimeSheetSummary from '@components/time/timesheetSummary'
import TimeSheet from '@classes/TimeSheet'
import TimeSheetStatus from '../../classes/TimeSheetStatus'

export default {
  name: 'TimesheetApprove',
  page: {
    title: 'Approve Timesheet',
  },
  components: {TimeSheetDetails, TimeSheetSummary},
  data: () => ({
    dialog: false,
    timeSheetStatus: {},
    rejectReason: '',
    timeSheetUser: {},
  }),
  created() {
    this.getTimesheetStatus()
  },
  computed: {
    ...authComputed,
    hasPermission() {
      if (this.timeSheetUser) {
        if (
          this.isSuperUser ||
          (this.timeSheetUser.SupervisorID == this.currentUser.ID &&
            this.timeSheetStatus.StatusID) ||
          (this.timeSheetUser.TimesheetVerifierID == this.currentUser.ID &&
            this.timeSheetStatus.StatusID == 1) ||
          (this.timeSheetUser.ID == this.currentUser.ID &&
            (!this.timeSheetStatus.StatusID ||
              this.timeSheetStatus.StatusID == 5))
        ) {
          return true
        }
      }
      return false
    },
    timesheetUserName() {
      if (this.timeSheetUser) {
        return this.timeSheetUser.FirstName + ' ' + this.timeSheetUser.LastName
      }
    },
    timesheetStatusText() {
      if (this.timeSheetStatus && this.timeSheetStatus.Status) {
        return this.timeSheetStatus.Status.Description
      } else {
        return 'Unsubmitted'
      }
    },
    breadcrumbs() {
      return [
        {
          text: 'Approval',
          disabled: false,
          to: '/timesheetsApproval',
        },
      ]
    },
    timesheetId() {
      return parseInt(this.$route.params.id)
    },
    submitText() {
      switch (this.timeSheetStatus.StatusID) {
        case 1:
          return 'verify'
          break
        case 2:
          return 'approve'
          break
        default:
          return 'submit'
      }
    },
  },
  methods: {
    createTimeSheetStatus: call('timeSheetStatus/createTimeSheetStatus'),
    handleError: call('errors/handleError'),
    getTimesheetUser() {
      let url = `/User/TimeSheet/${this.timesheetId}`
      this.$axios.get(url).then(
        (res) => {
          if (res.data) {
            this.timeSheetUser = res.data
          } else {
            this.timeSheetUser = {}
          }
        },
        (error) => {
          this.timeSheetUser = null
          this.handleError(error)
          this.error = error
          console.error(error)
        }
      )
    },
    getTimesheetStatus() {
      if (this.timesheetId) {
        let url = `/TimeSheetStatus/TimeSheet/${this.timesheetId}`
        this.$axios.get(url).then(
          (res) => {
            if (res.data) {
              this.timeSheetStatus = new TimeSheetStatus(res.data)
              this.timeSheetUser = res.data.TimeSheet.User
            } else {
              this.timeSheetStatus = {}
              this.getTimesheetUser()
            }
          },
          (error) => {
            this.timeSheetStatus = null
            this.timeSheetUser = null
            this.handleError(error)
            this.error = error
            console.error(error)
          }
        )
      }
    },
    submitNextStatus() {
      if (this.timeSheetStatus.StatusID) {
        var newStatusId = this.timeSheetStatus.StatusID + 1
        switch (this.timeSheetStatus.StatusID) {
          case 1:
            break
          case 2:
            newStatusId = this.timeSheetStatus.StatusID + 2
            break
          default:
            break
        }
        console.devlog(newStatusId)
        this.submitTimesheetStatus(newStatusId)
      }
    },
    submitTimesheetStatus(statusId, rejectReason) {
      if (statusId) {
        let reason = rejectReason || null
        let newStatus = new TimeSheetStatus({
          TimeSheetID: this.timesheetId,
          StatusID: statusId,
          RejectReason: reason,
          UserID: this.currentUser.ID,
          StatusDate: dayjs().toDate().toISOString().substr(0, 10)
        })
        this.createTimeSheetStatus(newStatus.root()).then((res) => {
          if (res.data) {
            this.$router.push({
              name: 'timesheetsApproval',
            })
          }
        })
      }
    },
    rejectTimeSheet() {
      this.submitTimesheetStatus(5, this.rejectReason)
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>